const lightTheme = {
  main: '#FFFFFF',
  inverse: '#1A181F',
  alt: '#F4F4F4',
  altWhite: '#FFFFFF',
  textMain: '#000000',
  cover: 'rgba(169, 169, 169, 0.69)',
  formGray: '#F8F8F8',
  textLight: '#696969',
  togglerFill: '#FFFFFF',
  grayText: '#6B6B6B',
  outline: 'blue solid 2px',
  formShadow: '0px 13.658536911010742px 40.975608825683594px 0px rgba(116, 116, 116, 0.25)',
};

export const darkTheme = {
  main: '#1A181F',
  inverse: '#FFFFFF',
  alt: '#201E26',
  altWhite: '#201E26',
  textMain: '#CECECE',
  grayText: '#ACACAC',
  formGray: '#3E3D43',
  textLight: '#B7B7B7',
  togglerFill: '#34313D',
  outline: 'blue solid 2px',
  cover: 'rgba(13, 13, 13, 0.69)',
  formShadow: '0px 13.658536911010742px 40.975608825683594px 0px rgba(0, 0, 0, 0.62)',
};

export default lightTheme;
